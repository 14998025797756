
.badge {
  padding: 4px;
  border-radius: 3px;
  text-transform: lowercase;
}
/* tooltip */
.tooltip {
  display:inline-block;
  position:relative;
  border-bottom:1px dotted #666;
  text-align:left;
}

.tooltip .top {
  min-width:200px; 
  top:-20px;
  left:50%;
  transform:translate(-50%, -100%);
  padding:10px 20px;
  color:#FFFFFF;
  background-color:#000000;
  font-weight:normal;
  font-size:13px;
  border-radius:8px;
  position:absolute;
  z-index:99999999;
  box-sizing:border-box;
  border:1px solid #111111;box-shadow:0 1px 8px rgba(0,0,0,0.5);
  /* display:none; */
}

.tooltip:hover .top {
  display:block;
}

.tooltip .top i {
  position:absolute;
  top:100%;
  left:50%;
  margin-left:-12px;
  width:24px;
  height:12px;
  overflow:hidden;
}

.tooltip .top i::after {
  content:'';
  position:absolute;
  width:12px;
  height:12px;
  left:50%;
  transform:translate(-50%,-50%) rotate(45deg);
  background-color:#000000;
  border:1px solid #111111;box-shadow:0 1px 8px rgba(0,0,0,0.5);
}
/* tooltip */
.modal .btn.btn-icon{
  width: 38px;
  height:38px;
}

.dropdown-menu-right .dropdown-item:hover, .dropdown-menu-right .dropdown-item:focus {
  color: #b1b1b5;
  text-decoration: none;
  background-color: #0d1028;
}
/* /*switch  */

input[switch] {
  display: none;
}
input[switch] + .c-switch-label {
  font-size: 1em;
  line-height: 1;
  width: 4.7rem;
  height: 1.5rem;
  background-color: #ddd;
  background-image: none;
  border-radius: 0.1275rem;
  padding: 2px 4px;
  cursor: pointer;
  display: inline-block;
  text-align: center;
  position: relative;
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.2) inset;
  font-family: inherit;
  vertical-align: middle;
  transition: all 0.1s ease-in-out;
}
input[switch] + .c-switch-label::before {
  /* Label */
  color: #656464;
  content: attr(data-off-label);
  display: block;
  font-weight: 500;
  font-size: 0.7rem;
  line-height: 1.2rem;
  position: absolute;
  right: 0.2166666667rem;
  margin: 0.2166666667rem;
  top: 0;
  text-align: center;
  min-width: 1.6666666667rem;
  overflow: hidden;
  transition: all 0.1s ease-in-out;
}
input[switch] + .c-switch-label::after {
  /* Slider */
  content: "";
  position: absolute;
  left: 0.1666666667rem;
  background-color: #f7f7f7;
  box-shadow: none;
  border-radius: 0.1275rem;
  height: 1.2rem;
  width: 1.2rem;
  transition: all 0.1s ease-in-out;
}
input[switch]:checked + .c-switch-label {
  background-color: #3da5f4;
  background-image: linear-gradient(
    rgba(255, 255, 255, 0.15),
    rgba(0, 0, 0, 0.2)
  );
  box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3) inset;
}
input[switch]:checked + .c-switch-label:before {
  color: #fff;
  content: attr(data-on-label);
  right: auto;
  left: 0.2166666667rem;
}
input[switch]:checked + .c-switch-label:after {
  left: 3.3555rem;
  background-color: #f7f7f7;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.3);
}

.disabled-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  /* text-indent: 4px; */
}
/* copy traget id */
.traget-id .form-control[readonly],
.app-id .form-control[readonly],
.app-id-height.form-control:disabled{
  background: #22243c33;
  cursor: inherit;
  color:#bfbfbf;
}

/* loader */

.lds-dual-ring {
  display: inline-block;
  width: 70%;
  height: 80px;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  text-align: center;
}
.lds-dual-ring::before {
  content: " ";
  display: block;
  width: 46px;
  height: 46px;
  margin: 1px;
  border-radius: 50%;
  border: 5px solid #2b2e4c;
  border-color: #dcdcdc transparent #dcdcdc transparent;
  animation: lds-dual-ring 1.2s linear infinite;
  background: #323748;
  margin: auto;
}
.progressing-text{
  text-shadow: 0px 0px 30px rgb(0, 0, 0);
  color: #fff;
}
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* table */
.table thead th {
  font-weight: 700;
  text-transform: none;
  padding-top: 1em;
  padding-bottom: 1em;
}
select.form-control {
  padding: 0.4375rem 0.6rem;
}
.close.Edit {
  opacity: 1;
}
.asset-table th {
  font-size: 1.02em !important;
}
.asset-table tr th {
  background: #ffffff1f;
}
/* card  */
.css-1g6gooi input{
  color:hsl(340, 3%, 78%) !important;
}
/* card */

 .progress{
  overflow: hidden;
  background-color: #f5f5f5;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 2px rgba(0,0,0,.1);
  box-shadow: inset 0 1px 2px rgba(0,0,0,.1)
}
.progress-bar-striped{
  background-image: -webkit-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
    background-image: -o-linear-gradient(45deg,rgba(255,255,255,.15) 25%,transparent 25%,transparent 50%,rgba(255,255,255,.15) 50%,rgba(255,255,255,.15) 75%,transparent 75%,transparent);
     -webkit-background-size: 40px 40px;   
}
.progress-sm{
  height:4px !important;
} 

.app-id-height{
  height:25px;
}
.change-password-wrapper{
  min-height: calc(100vh - 124px);
}
/* search */
.refresh-btn-block {
  display: inline;
}
.card-search .form-control{
  /* min-width: 320px; */
  background: #131633;
  border-radius:0.188888rem;
  height:38px;
  border: 1px solid #131633;
}
.card-search{
  display: inline;
}
.modal-backdrop{
  z-index:1029;
}
.owner_label .badge{
  text-transform: capitalize;
}
/* forgot password */
.change-pswd-min-width{
  min-width: 320px;
}
.password-tooltip{
  position:relative;
}
.password-tooltip i.mdi-information-outline{
position: absolute;
right: 4px;
top: 13px;
font-size: 24px;
}
/* tooltip */
[data-tooltip] {
  position: relative;
  cursor: pointer;
}

/* Hide the tooltip content by default */
[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
}

/* Position tooltip above the element */
[data-tooltip]:before {
  position: absolute;
  bottom: 85%;
  left: 50%;
  margin-bottom: 5px;
  margin-left: -80px;
  padding: 7px;
  width: 320px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  border-radius: 3px;
  background-color: #000;
  background-color: hsla(234, 17%, 48%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
  z-index:2000;
}
/* card tool tip */
.gallery-card-height[data-tooltip]:before {
  bottom: 50%;
  top: auto;
  left:0;
  right: 0;
  margin-bottom: 5px;
  margin-left:auto;
  max-width: 320px;
  width: auto;
  margin-left: 15px;
  margin-right: 15px;
  background-color:hsla(350, 85%, 64%, 0.84);
  word-break: break-all;
}
.gallery-card-height[data-tooltip]:after {
  border-top: 5px solid hsla(350, 85%, 64%, 0.84);
  top:48%;
}
/* /card tool tip */
/* Triangle hack to make tooltip look like a speech bubble */
[data-tooltip]:after {
  position: absolute;
  bottom: 85%;
  left: 50%;
  margin-left: -19px;
  width: 0;
  border-top: 5px solid #000;
  border-top: 5px solid hsl(234, 17%, 48%);
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  content: " ";
  font-size: 0;
  line-height: 0;
}

/* Show tooltip content on hover */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}
/* select{
  -webkit-appearance: none;
} */
.text_shadow{
  text-shadow: 1px 1px 20px #000000
}
.card_status{
  text-transform: capitalize;
  font-weight: 600;
}
 input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  -moz-appearance: none;
  -moz-appearance: textfield;
  margin: 0; 
} 
button.user-profile-remove{
  background: #000 !important;
    width: 25px;
    height: 25px;
    border-radius: 50px !important;
    font-weight: 400;
    position: absolute;
    right:3px;
    top:3px;
    z-index: 100000;
}
button.user-profile-remove span{
  color:white;
}
.user-icon .icon-upload input{
  height: 138px;
}

/* select */
.bulleted .plain-select {
  width: 89%;
}
.plain-select  {
  width:82%;
  position: relative;
}
.plain-select select {
	line-height:normal;
	/* height:38px; */
	-webkit-appearance:none;
	-moz-appearance:none;
	-ms-appearance:none;
	appearance:none;
}

.plain-select ::-ms-expand {
 display:none;
}
.plain-select:after{
	content:"";
	position:absolute;
	z-index:2;
	right:8px;
	top:50%;
	margin-top:-3px;
	height:0;
	width:0;
	border-top:6px solid #aaa7a7;
	border-left:6px solid transparent;
	border-right:6px solid transparent;
	pointer-events:none;
}
.hide-arrow-asset:after{
display: none;
}
.width-38{
width:30%;
}
.badge.custom-label{
  background-color: #5f65ab;
}
.app_name_wrap {
  font-weight: bold;
  color: #3da5f4;
  font-size: 14px;
  padding-right: 22px;
  width: 100%;
  text-align: right!important;
  padding-top: 7px;
}
.box-rgt {
  float: right;
  height: 55px;
  justify-content: flex-end;
  display: grid;
  margin-bottom: 5px;
  margin-top: 0;
}
.select-wrapp .css-1wy0on6  {
  display: none;
}
.select-wrapp .css-1wy0on6 {
  display: none;
}
 .select-wrapp  .css-vj8t7z {
  border: none!important;
  }

  .select-wrapp .select-container .css-vj8t7z, .select-container .css-2o5izw, .select-container .css-162g8z5
  {
    border: none!important;
  }
  .select-wrapp .select-container .css-vj8t7z, .select-container .css-2o5izw, .select-container .css-1szy77t-control, .select-container .css-bg1rzq-control, .select-container .css-162g8z5:hover {
    background-color: transparent;
  }
  .selsct-btn-block {
    width: 22%;
  }
  .selsct-btn-block p {
    padding-top: 8px;
    float: left;
    width: 35%;
  }